const MAIL = 'damirviro86@gmail.com'
const ADDRESS = 'Mosećka 95 A, 21 000 Split'
const TELEPHONE = '+385 21 278 461'
const MOBILE = '+385 98 175 1344'

export default Object.freeze({
    MAIL: MAIL,
    ADDRESS: ADDRESS,
    TELEPHONE: TELEPHONE,
    MOBILE: MOBILE,
})
