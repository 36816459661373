<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              :title="$t('ContactUs.title')"
            />
            <base-body space="0">
              <v-row>
                <v-col
                  cols="4"
                  md="2"
                  sm="3"
                >
                  <v-avatar
                    size="80"
                    tile
                  >
                    <v-img
                    class="rounded-img"
                      :src="require('@/assets/logo_only_circle.png')"
                    />
                  </v-avatar>
                </v-col>
                <v-col
                  cols="8"
                  md="10"
                  sm="9"
                >
                  <span class="white--text">
                    {{ $t('ContactUs.text') }}
                  </span>
                </v-col>
              </v-row>
            </base-body>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="white"
              :href="`mailto:${email}`"
              large
              outlined
              target="_blank"
              class="mt-10"
            >
              {{ $t('ContactUs.btnText') }}
            </base-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import constants from '@/config.js'
  export default {
    name: 'SectionContactUs',
    email: null,
    data: () => ({
      email: constants.MAIL,
    }),
  }
</script>

<style>
  .rounded-img {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}
</style>
